import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import CommonHeader from "../Common/CommonHeader";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Addnotification } from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Please select a user type"),
  title: Yup.string().trim().required("Required"),
  desc: Yup.string().trim().required("Required"),
});

export default function CreateNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const InitialValues = {
    type: "",
    title: "",
    desc: "",
  };

  const handleSubmit = async (values) => {
    const payload = {
      title: values?.title,
      send_to: values?.type,
      desc: values?.desc,
    };
    try {
      const data = await dispatch(Addnotification(payload));
      console.log(data);
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        navigate("/notifications");
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error, "error occur");
    }
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="filters pb-2">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Create Notification</h2>
            </div>
          </div>
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="create-notification-page">
                  <div className="category-field">
                    <div className="input-select">
                      <label>Select User</label>
                      <Form.Select
                        aria-label="Default select example"
                        name="type"
                        value={values?.type}
                        onChange={handleChange}
                      >
                        <option> Select </option>
                        <option value="2">All Users</option>
                        <option value="1">Owners</option>
                        <option value="0">Users</option>
                      </Form.Select>
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="modal-input">
                      <label>Title</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Type here"
                          name="title"
                          value={values?.title}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error-message"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="textarea-input">
                    <Form.Group className="mt-4">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Type here"
                        name="desc"
                        value={values?.desc}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="desc"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </div>
                  <div className="create-btn">
                    <button type="submit">Create</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
