import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import {
  getpropertydetails,
  verifyProperty,
} from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import CommonHeader from "../Common/CommonHeader";
import { FaRegFilePdf } from "react-icons/fa";
import ImagePreviewModal from "../Common/ImagePreviewModal";

export default function UserProperty() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [show, setshow] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [propertiesdetails, setPropertiesDetails] = useState({});
  console.log(propertiesdetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getpropertydetails({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setPropertiesDetails(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleAcceptRequest = () => {
    dispatch(verifyProperty({ propertyId: id, type: 1 }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setshow(false);
          navigate("/user");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
      


  const handlePrivewImagesOpen = (img) => {
    setImageModal(true);
    setSelectedImage(img);
  };

  const handlePrivewImagesClose = () => {
    setImageModal(false);
    setSelectedImage("");
  };



  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box property-page">
                <div className="pro-perty-heading">
                  <h2>Property Information</h2>
                </div>
                <div className="my-property-prof">
                  {propertiesdetails?.property_video &&
                  propertiesdetails.property_video.length > 0 ? (
                    <video
                      src={`${url}${propertiesdetails.property_video[0]}`}
                      alt="Property"
                      controls
                    />
                  ) : (
                    <p>Video not found</p>
                  )}
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Property Name:{" "}
                          <span>{propertiesdetails?.hotel_name || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Email:{" "}
                          <span>
                            {propertiesdetails?.desk_address || "N/A"}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Contact:{" "}
                          <span>
                          {propertiesdetails?.desk_phone_number
                              ? propertiesdetails.desk_country_number
                                ? `${propertiesdetails.desk_country_number} ${propertiesdetails.desk_phone_number}`
                                : `${propertiesdetails.desk_phone_number}`
                              : "N/A"}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          City: <span>{propertiesdetails?.city || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Address:
                          <span>{propertiesdetails?.address || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          No. of Floors:{" "}
                          <span>{propertiesdetails?.floors || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                        No. of Rooms: <span>{propertiesdetails?.base_rooms || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Average Price:{" "}
                          <span>
                            {propertiesdetails?.average_price || "N/A"}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="accept-request-btn">
                    <button onClick={() => setshow(true)}>
                      Accept Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-over-view">
              <div className="inner-box-images">
                <div className="outer-shadow-box m-0">
                  <div className="inner-filter-field">
                    <div className="image-heading-text">
                      <h2>Documents</h2>
                    </div>

                    <div class="table-responsive new-over-view">
                      <div className="document-images">
                        <div className="pen-card">
                          <p>Pan Card</p>
                          {propertiesdetails?.pan_card?.includes("pdf") ? (
                            <a
                              href={`${url}${propertiesdetails?.pan_card}`}
                              target="_blank"
                            >
                              <FaRegFilePdf size={100} />
                            </a>
                          ) : propertiesdetails?.pan_card?.includes("jpg") ||
                            propertiesdetails?.pan_card?.includes("jpeg") ||
                            propertiesdetails?.pan_card?.includes("png") ? (
                            <img
                              src={`${url}${propertiesdetails.pan_card}`}
                              alt="Pan Card"
                            />
                          ) : (
                            "Not an image or PDF"
                          )}
                        </div>
                        <div className="pen-card">
                          <p>Registration Proof</p>

                          {propertiesdetails?.registration_proof?.includes(
                            "pdf"
                          ) ? (
                            <a
                              href={`${url}${propertiesdetails?.registration_proof}`}
                              target="_blank"
                            >
                              <FaRegFilePdf size={100} />
                            </a>
                          ) : propertiesdetails?.registration_proof?.includes(
                              "jpg"
                            ) ||
                            propertiesdetails?.registration_proof?.includes(
                              "jpeg"
                            ) ||
                            propertiesdetails?.pan_card?.includes("png") ? (
                            <img
                              src={`${url}${propertiesdetails.pan_card}`}
                              alt="Pan Card"
                            />
                          ) : (
                            "Not an image or PDF"
                          )}
                        </div>
                        <div className="pen-card">
                          <p>Lease Agreement</p>

                          {propertiesdetails?.lease_agreement?.includes(
                            "pdf"
                          ) ? (
                            <a
                              href={`${url}${propertiesdetails?.lease_agreement}`}
                              target="_blank"
                            >
                              <FaRegFilePdf size={100} />
                            </a>
                          ) : propertiesdetails?.lease_agreement?.includes(
                              "jpg"
                            ) ||
                            propertiesdetails?.lease_agreement?.includes(
                              "jpeg"
                            ) ||
                            propertiesdetails?.pan_card?.includes("png") ? (
                            <img
                              src={`${url}${propertiesdetails?.lease_agreement}`}
                              alt="Pan Card"
                            />
                          ) : (
                            "Not an image or PDF"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="outer-shadow-box ">
                  <div className="inner-filter-field">
                    <div className="image-heading-text">
                      <h2>Photos</h2>
                    </div>

                    <div class="table-responsive new-over-view">
                      <div className="document-images">
                        {propertiesdetails?.property_images &&
                        propertiesdetails?.property_images?.length > 0
                          ? propertiesdetails?.property_images?.map((res) => {
                              return <img onClick={() =>
                                handlePrivewImagesOpen(
                                  res
                                )
                              } src={`${url}${res}`} />;
                            })
                          : "Photos not Found"}

                        {/* {propertiesdetails?.property_images &&
                        propertiesdetails.property_images.length > 0
                          ? // Create a new array that includes the existing images and the new image
                            [
                              ...propertiesdetails.property_images,
                              "1728642576001--IMG_0370.JPG",
                            ].map((res, index) => {
                              return (
                                <>
                                  <img
                                    key={index}
                                    src={`${url}${res}`}
                                    alt={`Property Image ${index}`}
                                  />
                                </>
                              );
                            })
                          : "Photos not Found"} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="account-setting">
            <div className="delete-account">
              <div className="rules-policies">
                <h1>Rules & policies</h1>
                <p>Checkout Time: {propertiesdetails?.rules?.check_out || "N/A"}</p>
              </div>
              <div className="cancellation-disp">
                  <p>
                    {propertiesdetails?.rules?.cancellation === 0
                      ? "cancel up to 24" 
                      : propertiesdetails?.rules?.cancellation === 1
                      ? "Refundable Anytime"
                      : "Non-Refundable"}
                  </p>

                  {}
                  {propertiesdetails?.rules?.cancellation === 0 && (
                    <span>
                      Your amount will be refundable only when your cancellation
                      is done before 24 hrs of booking.
                    </span>
                  )}

                  {propertiesdetails?.rules?.cancellation === 1 && (
                    <span>
                      Your amount will be refundable at any time of your
                      cancellation.
                    </span>
                  )}
                </div>

              <div className="cancellation-policy">
                <h2>Acceptable Identity Proofs</h2>
                <div className="identity-pro">
                  {propertiesdetails?.rules?.id_proofs &&
                  propertiesdetails?.rules?.id_proofs?.length > 0
                    ? propertiesdetails?.rules?.id_proofs
                        ?.map((res, index) => {
                          return res === 0
                            ? "Aadhaar Card"
                            : res === 1
                            ? "Passport"
                            : res === 2
                            ? "Driving License"
                            : "Pan Card";
                        })
                        .join(", ")
                    : "N/A"}
                  {/* <ul>
                    <li>Aadhar Card</li>
                    <li>Driving License</li>
                  </ul>
                </div>
                <div className="identity-pro">
                  <ul>
                    <li>Passport</li>
                    <li>Pan card</li>
                  </ul> */}
                </div>
              </div>

              <div className="cancellation-policy">
                <h2>Property Restrictions</h2>
                <div className="identity-pro pro-restrictions">
                  <ul>
                    {" "}
                    {propertiesdetails?.rules?.is_smoking_allowed === 1 && (
                      <li>Smoking Allowed</li>
                    )}
                    {propertiesdetails?.rules?.unmarried_couples === 1 && (
                      <li>Unmarried Couples</li>
                    )}
                    {propertiesdetails?.rules?.below_18 === 1 && (
                      <li>Below 18</li>
                    )}
                    {propertiesdetails?.rules?.pet_allowed === 1 && (
                      <li>Pets Allowed</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="properties-amenities">
              <div className="inner-properties">
                <h2>Properties Amenities</h2>

                <p>
                  Restaurant Name :{" "}
                  {propertiesdetails?.amenities?.resturant === 1
                    ? `${propertiesdetails?.amenities?.resturant_name}`
                    : "N/A"}
                </p>
                <div className="line-hr">
                  <div className="identity-pro">
                    <ul>
                      {propertiesdetails?.amenities?.room_service === 1 && (
                        <li>Room Service</li>
                      )}

                      {propertiesdetails?.amenities?.outdoor_sports === 1 && (
                        <li>Outdoor Sports</li>
                      )}

                      {propertiesdetails?.amenities?.newspaper === 1 && (
                        <li>Newspaper</li>
                      )}
                      {propertiesdetails?.amenities?.ac === 1 && <li>AC</li>}

                      {propertiesdetails?.amenities?.beauty_spa === 1 && (
                        <li>Beauty & Spa</li>
                      )}

                      {propertiesdetails?.amenities?.charging_station === 1 && (
                        <li>Charging Station</li>
                      )}
                    </ul>
                  </div>
                  <div className="identity-pro">
                    <ul>
                      {propertiesdetails?.amenities?.parking === 1 && (
                        <li>Parking</li>
                      )}
                      {propertiesdetails?.amenities?.food_drink === 1 && (
                        <li>Food & Drinks</li>
                      )}
                      {propertiesdetails?.amenities?.indoor_activities ===
                        1 && <li>Food & Drinks</li>}

                      {propertiesdetails?.amenities?.luggage_storage === 1 && (
                        <li>Language Storage</li>
                      )}
                      {propertiesdetails?.amenities?.printer === 1 && (
                        <li>Printer</li>
                      )}

                      {propertiesdetails?.amenities?.seating_areas === 1 && (
                        <li>Seating Areas</li>
                      )}

                      {propertiesdetails?.amenities?.security === 1 && (
                        <li>Security</li>
                      )}

                      {propertiesdetails?.amenities?.wifi === 1 && (
                        <li>Wifi</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>

        <ImagePreviewModal
          handlePrivewImagesClose={handlePrivewImagesClose}
          imageModal={imageModal}
          selectedImage={selectedImage}
        />

      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text text-center">
            Are you sure you want to verify this Request?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none ">
          <Button className="theme-btn done-btn" onClick={handleAcceptRequest}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
