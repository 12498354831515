import React from "react";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import PaginationComponet from "../Components/Layout/Pagination";

export const TravlerAccount = ({
  handlepagechnage,
  Userdata,
  currentPage,
  limit,
  activePopoverId1,
  handleTogglePopover1,
  popover1,
  activeTab,
}) => {
  const userdata = Userdata;
  const handlePageChange = handlepagechnage;
  const navigate = useNavigate();

  return (
    <div class="table-responsive">
      <Table size="sm" className="table-cmn">
        <thead>
          <tr>
            <th>S.no.</th>
            <th>User Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {userdata?.data?.users?.map((res, index) => {
            const serialNumber =
              ((currentPage == "1" ? 1 : currentPage) - 1) * limit + index + 1;
            return (
              <tr key={index}>
                <td>{serialNumber}</td>
                <td>
                  <div className="first-user">
                    <div
                      className="user-profile"
                      onClick={() => navigate(`/user-over-view/${res?._id}`)}
                    >
                      <div
                        className="user-id travele-user-name"
                        style={{ cursor: "pointer" }}
                      >
                        <p>
                          {res?.first_name && res?.last_name
                            ? `${res.first_name} ${res.last_name}`
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="user-role">
                    <p>{res?.email || "N/A"}</p>
                  </div>
                </td>
                <td>
                  <div className="cont-num">
                    {" "}
                    {res?.phone_number
                      ? res.country_code
                        ? `${res.country_code} ${res.phone_number}`
                        : `${res.phone_number}`
                      : "N/A"}{" "}
                  </div>
                </td>
                <td>
                  <div
                    className={`${res?.is_active === 1 ? "active" : "pending"}`}
                  >
                    <p>{res?.is_active === 1 ? "Active" : "Inactive"}</p>
                  </div>
                </td>

                <td>
                  <div className="">
                    <OverlayTrigger
                      trigger="click"
                      show={activePopoverId1 === res._id}
                      placement="bottom"
                      overlay={popover1(res?._id, res?.role)}
                      onToggle={() => {
                        handleTogglePopover1(res._id);
                      }}
                      rootClose
                    >
                      <img
                        src={require("../Assets/Images/options.svg").default}
                        alt="Options"
                        style={{ cursor: "pointer" }}
                      />
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination-section">
        <div className="showing-user">
          <p>
            {" "}
            Showing {1 + (currentPage - 1) * limit} -{" "}
            {Math.min(currentPage * limit, userdata?.data?.totalCount)} of{" "}
            {userdata?.data?.totalCount} results
          </p>
        </div>
        <div className="pagination-block">
          <PaginationComponet
            currentPage={currentPage}
            totalCount={userdata?.data?.totalCount}
            limit={userdata?.data?.totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
