import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";

// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
  "chnagePassword",
  async (details) => {
    const data = await AdminAPI.patch(`/changePassword`, details);
    console.log(data);
    return data;
  }
);

// ===== Forget Password =======

export const forgetPass = createAsyncThunk("forgetPass", async (details) => {
  const data = await AdminAPI.post(`/forgetpassword`, details);
  return data;
});

// ======= Reset Password ===========

export const ResetPass = createAsyncThunk("ResetPass", async (details) => {
  const data = await AdminAPI.post(`/resetpassword`, details);
  return data;
});

// ==== get admin details to edit profile ====
export const getAdmindetails = createAsyncThunk("getAdmindetails", async () => {
  const data = await AdminAPI.get(`/getProfile`);
  console.log(data);
  return data;
});

// ==================== edit admin profile ======================

export const editAdminprofile = createAsyncThunk(
  "editAdminprofile",
  async (details) => {
    const data = await AdminAPI.put(`/editProfile`, details);
    console.log(data);
    return data;
  }
);

// ========= get user list ============

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/userManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    if (details.role !== undefined && details.role !== null) {
      url += `&type=${details.role}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// =============== Activate or deactivate user =================

export const Suspenduser = createAsyncThunk("Suspenduser", async (details) => {
  const data = await AdminAPI.put(`/activateDeactivateUser`, details);
  console.log(data);
  return data;
});

// =============== get user profile =================

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminAPI.get(`/userProfile?userId=${details?.userId}`);
    console.log(data);
    return data;
  }
);

// =========  get property details by id  =========

export const getpropertydetails = createAsyncThunk(
  "getpropertydetails",
  async (details) => {
    const data = await AdminAPI.get(
      `/getPropertyById?propertyId=${details?.id}`
    );
    console.log(data);
    return data;
  }
);


// ======== verify Property ==============

export const verifyProperty = createAsyncThunk(
  "verifyProperty",
  async (details) => {
    const data = await AdminAPI.patch(`/verifyProperty`, details);
    console.log(data);
    return data;
  }
);

// ========= get user booking list ============

export const getbookings = createAsyncThunk("getbookings", async (details) => {
  let url = `/userBookingManagement?page=${details?.page}&limit=${details?.limit}&userId=${details?.id}`;
  const data = await AdminAPI.get(url);
  console.log(data);
  return data;
});


// =========  Delete Admin Account =============

export const DeleteUser = createAsyncThunk(
  "DeleteUser",
  async (details) => {
    const data = await AdminAPI.delete(`/deleteAccount?userId=${details?.userId}`);
    console.log(data);
    return data;
  }
);


// ===========  suspend Admin Account ============

export const Suependuser = createAsyncThunk(
  "Suependuser",
  async (details) => {
    const data = await AdminAPI.put(`/activateDeactivateUser`, details);
    console.log(data);
    return data;
  }
);



// =========  Delete Property =============

export const DeleteProperty = createAsyncThunk(
  "DeleteProperty",
  async (details) => {
    const data = await AdminAPI.delete(`/deleteProperty?propertyId=${details?.propertyId}`);
    console.log(data);
    return data;
  }
);

// ===========  suspend Property ============

export const SuspendProperty = createAsyncThunk(
  "SuependProperty",
  async (details) => {
    const data = await AdminAPI.put(`/activateDeactivateProperty`, details);
    console.log(data);
    return data;
  }
);



// ========= get booking list ============

export const bookingmanagement = createAsyncThunk(
  "bookingmanagement",
  async (details) => {
    let url = `/bookingManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);


// ======= get booking information by id =======

export const getbookingInfo = createAsyncThunk(
  "getbookingInfo",
  async (details) => {
    const data = await AdminAPI.get(`/getBookingById/?bookingId=${details?.bookingId}`);
    console.log(data);
    return data;
  }
);

// =========  dowanload booking list ==========

export const dowanloadbookings = createAsyncThunk(
  "dowanloadbookings",
  async (details) => {
    const data = await AdminAPI.get(`/bookingManagement`, details);
    console.log(data);
    return data;
  }
);



// ========= get All Tickets ============

export const gettickets = createAsyncThunk(
  "gettickets",
  async (details) => {
    let url = `/getAllTickets?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);


// =========  Dowanload ticklets  =========

export const dowanloadTickets = createAsyncThunk(
  "dowanloadTickets",
  async (details) => {
    const data = await AdminAPI.get(`/getAllTickets`, details);
    console.log(data);
    return data;
  }
);



// ============ Support status  ============


export const updatesupportstatus = createAsyncThunk(
  "updatesupportstatus",
  async (details) => {
    const data = await AdminAPI.put(`/closeTicket`, details);
    return data;
  }
);

 //  =======  Notifiaction Listing  =======

export const getnotificationlist = createAsyncThunk(
  "getnotificationlist",
  async (details) => {
    let url = `/getAllNotifications?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);


  
// =========  Add Notification =============

export const Addnotification  = createAsyncThunk(
  "Addnotification",
  async (details) => {
    const data = await AdminAPI.post(`/addNotification`, details);
    console.log(data);
    return data;
  }
);


// =========  Delete Notification =============

export const DeleteNotification  = createAsyncThunk(
  "DeleteNotification",
  async (details) => {
    const data = await AdminAPI.delete(`/deleteNotification?notificationId=${details?.notificationId}`);
    console.log(data);
    return data;
  }
);


// =========  get Dashboard data =============

export const getdashboard  = createAsyncThunk(
  "getdashboard",
  async () => {
    const data = await AdminAPI.get(`/dashboard`);
    console.log(data);
    return data;
  }
);
