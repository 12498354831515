import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import CommonHeader from "../Common/CommonHeader";
import { useDispatch } from "react-redux";
import {
  bookingmanagement,
  dowanloadbookings,
} from "../Redux/Actions/AdminActions";
import SearchBar from "../Common/SerchBar";
import PaginationComponet from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";

export default function Booking() {
  const [activeToggle, setActiveToggle] = useState("images");
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [activePopoverId1, setActivePopoverId1] = useState(null);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [Bookingdetails, setbookingdetails] = useState([]);
  const [pagi, setpagi] = useState("");

  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      bookingmanagement({
        page: pageNumber,
        limit: limit,
        role: activeTab,
      })
    );
  };

  useEffect(() => {
    dispatch(
      bookingmanagement({ limit: limit, search: search, page: currentPage })
    )
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setbookingdetails(response?.payload?.data?.data?.bookings);
          setpagi(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, [limit, currentPage, search]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(dowanloadbookings());
      console.log(response);

      const allData = response?.payload?.data?.data?.bookings;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handlenavigate = (id) => {
    navigate(`/booking-information/${id}`);
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Booking Management</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setsearch} />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Booking iD</th>
                    <th>Customer name</th>
                    <th>Property Name</th>
                    <th>Booking Date</th>
                    <th>Contact</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {Bookingdetails?.map((res, index) => {
                    const serialNumber =
                      ((currentPage == "1" ? 1 : currentPage) - 1) * limit +
                      index +
                      1;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>
                          <div
                            className="user-id booking-id-bold"
                            style={{ cursor: "pointer" }}
                            onClick={() => handlenavigate(res?._id)}
                          >
                            <p>{res?.bookingId || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="first-user">
                            <div
                              className="user-profile"
                              // onClick={() => navigate("/booking-information")}
                            >
                              {/* <img
                                src={
                                  require("../Assets/Images/user.svg").default
                                }
                              /> */}
                              <div className="user-id">
                                <p>
                                  {res?.userId?.first_name &&
                                  res?.userId?.last_name
                                    ? `${res?.userId?.first_name} ${res?.userId?.last_name}`
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>{res?.propertyId?.hotel_name || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>
                              {res?.check_in
                                ? new Date(res.check_in).toLocaleDateString(
                                    "en-GB",
                                    {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    }
                                  )
                                : "N/A"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            {res?.traveller_country_code &&
                            res?.traveller_phone_number
                              ? `${res?.traveller_country_code} ${res?.traveller_phone_number} `
                              : "N/A"}
                          </div>
                        </td>
                        <td>
                          <div
                            className={
                              res?.is_cancelled === 0 ? "checked-in" : "pending"
                            }
                          >
                            <p>
                              {res?.is_cancelled === 0
                                ? "Checked In"
                                : "Cancelled"}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (currentPage - 1) * limit} -{" "}
                    {Math.min(currentPage * limit, pagi?.totalCount)} of{" "}
                    {pagi?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={pagi?.totalCount}
                    limit={pagi?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
