import React from 'react'
import { useNavigate } from 'react-router-dom';
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import { OverlayTrigger, Popover } from "react-bootstrap";
import PaginationComponet from "../Components/Layout/Pagination";

export const Newrequest = ({
  handlepagechnage,
  Userdata,
  currentPage,
  limit,
  activeTab

}) => {

  const userdata = Userdata;
  const handlePageChange = handlepagechnage;
  const navigate = useNavigate();

  return (
  
                  <div class="table-responsive">
                    <div class="table-responsive">
                      <Table size="sm" className="table-cmn">
                        <thead>
                          <tr>
                            <th>S.no.</th>
                            <th>Property Name</th>
                            <th>Owner Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userdata?.data?.users?.map((res, index) => {
                            const serialNumber =
                              ((currentPage == "1" ? 1 : currentPage) - 1) *
                                limit +
                              index +
                              1;
                            return (
                              <tr>
                                <td>{serialNumber}</td>

                                <td>
                                  <div className="cont-num">
                                    {res?.hotel_name || "N/A"}
                                  </div>
                                </td>
                                <td>
                                  <div className="first-user">
                                    <div className="user-profile">
                                      {/* <img
                                        src={
                                          require("../Assets/Images/user.svg")
                                            .default
                                        }
                                      /> */}
                                      <div className="user-role">
                                        <p>{`${res?.userId?.first_name} ${res?.userId?.last_name}`|| "N/A"}</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                
                                <td>
                                  <p>{res?.userId?.email  || "N/A"} </p></td>
                                
                                <td>
                                  
                                  <div className="user-role">
                                    <p>
                                      <p>
                                      {`${res?.userId?.country_code} ${res?.userId?.phone_number}`|| "N/A"}
                                      </p>
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="pending view-green"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigate(`/user-property/${res?._id}`)
                                    }
                                  >
                                    <p>View</p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <div className="pagination-section">
                        <div className="showing-user">
                          <p>
                            {" "}
                            Showing {1 + (currentPage - 1) * limit} -{" "}
                            {Math.min(
                              currentPage * limit,
                              userdata?.data?.totalCount
                            )}{" "}
                            of {userdata?.data?.totalCount} results
                          </p>
                        </div>
                        <div className="pagination-block">
                          <PaginationComponet
                            currentPage={currentPage}
                            totalCount={userdata?.data?.totalCount}
                            limit={userdata?.data?.totalPages}
                            onPageChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
             
  )
}

