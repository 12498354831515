import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import CommonHeader from "../Common/CommonHeader";
import { getbookingInfo } from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import Logo from "../Assets/Images/place_holder-BHGM.png";

export default function BookingInformation() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [bookingdetails, setBookingdetails] = useState({});
  console.log(bookingdetails);

  useEffect(() => {
    dispatch(getbookingInfo({ bookingId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setBookingdetails(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  const totalRoomCount = bookingdetails?.rooms?.reduce(
    (acc, room) => acc + (room?.roomCount || 0),
    0
  );
  
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <CommonHeader />
        </div>
      </Container>

      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box">
                <div className="my-overview-prof">
                 
                    <img
                      src={Logo}
                      alt="Profile"
                    />
                 

                  <h2>{bookingdetails?.traveller_name || "N/A"}</h2>
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Username:{" "}
                          <span>{bookingdetails?.traveller_name || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Email:{" "}
                          <span>
                            {bookingdetails?.traveller_email || "N/A"}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Contact:{" "}
                          <span>
                            {" "}
                            {bookingdetails?.traveller_country_code &&
                            bookingdetails?.traveller_phone_number
                              ? `${bookingdetails?.traveller_country_code} ${bookingdetails?.traveller_phone_number}`
                              : "N/A"}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-over-view">
              <div className="inner-box-images">
                <div className="outer-shadow-box mt-0">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Booking Information</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-grid">
                          <div className="booking-label">
                            <label>Booking ID</label>

                            <p> {bookingdetails?.bookingId || "N/A"} </p>
                          </div>
                          <div className="booking-label">
                            <label>Property Name</label>
                            <p>
                              {bookingdetails?.propertyId?.hotel_name || "N/A"}
                            </p>
                          </div>

                          <div className="booking-label">
                            <label>Guests </label>
                            <p>{bookingdetails?.guest || "N/A"}</p>
                          </div>
                          <div className="booking-label">
                            <label>Rooms</label>
                            <p>
                              
                              {totalRoomCount}
                            </p>
                          </div>

                          <div className="booking-label">
                            <label>Check In Date</label>
                            {/* <p>22-Jan-2023 | 09:30 AM</p> */}
                            <p>
                            {bookingdetails?.check_in
                                          ? new Date(
                                              bookingdetails.check_in
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "short",
                                              year: "numeric",
                                            })
                                          : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="outer-shadow-box">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Booking Information</h2>
                      </div>
                      <div className="booking-info">
                        <div className="">
                          {/* <p>Customer Checked In</p> */}
                          {/* <p>{bookingdetails?.is_cancelled === 0 ? "Customer Checked In" : "Cancelled"}</p> */}
                          <div
                            className={
                              bookingdetails?.is_cancelled === 0 ? "checked-in" : "pending"
                            }
                          >
                            <p>
                              {bookingdetails?.is_cancelled === 0
                                ? "Checked In"
                                : "Cancelled"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
