import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import CommonHeader from "../Common/CommonHeader";
import { dowanloadTickets, gettickets, updatesupportstatus } from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import PaginationComponet from "../Components/Layout/Pagination";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

export default function ChatSupport() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [page, setpage] = useState(1);
  const [flag, setflag] = useState(true);
  const [ticketList, setTicketList] = useState([]);
  const [pagi, setpagi] = useState("");
  const [ID, setID] = useState("");
  const [value, setvalue] = useState("");
  const [show, setshow] = useState(false);


  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      gettickets({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    dispatch(gettickets({ page: page, limit: limit, search: search }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setTicketList(res?.payload?.data?.data?.tickets);
          setpagi(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, limit, search,flag]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(dowanloadTickets());
      console.log(response);

      const allData = response?.payload?.data?.data?.tickets;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);


  const handlestaus = () => {
    dispatch(updatesupportstatus({ ticketId: ID, status: value }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setflag(!flag);
          setshow(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Support</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search User"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                {/* <div className="create-date-range">
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/upload-white-icon.svg")
                            .default
                        }
                      />
                    </span>
                    Create Date Range
                  </p>
                </div> */}
                <div
                  className="export-box download-btn-st"
                  onClick={fetchDataAndExportToExcel}
                >
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/download-icon.svg").default
                        }
                      />
                    </span>
                    Download
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Ticket iD</th>
                    <th>Date</th>
                    <th>Sender name</th>
                    <th>Contact</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ticketList?.map((res, index) => {
                    const serialNumber =
                      ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>
                          <div className="user-id">
                            <p>{res?.ticketId || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p> {res?.createdAt
                                          ? new Date(
                                              res.createdAt
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "short",
                                              year: "numeric",
                                            })
                                          : "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>
                              {res?.userId?.first_name && res?.userId?.last_name
                                ? `${res?.userId?.first_name} ${res?.userId?.last_name}`
                                : "N/A"}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="user-role">
                            <p>
                              {res?.userId?.country_code &&
                              res?.userId?.phone_number
                                ? `${res?.userId?.country_code} ${res?.userId?.phone_number}`
                                : "N/A"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>{res?.desc || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div
                            className={
                              res?.status === 1
                                ? "pending closed"
                                : "checked-in open"
                            }
                          >
                            <p>{res?.status === 1 ? "Close" : "Open"}</p>
                          </div>
                        </td>
                        <td>
                      <div className="select-box action-dropdown">
                        <Form.Select
                          aria-label="Default select example"
                          value={res?.status}
                          // disabled={res?.status == "2" ? true : false}
                          onChange={(e) => {
                            setshow(true);
                            setID(res?._id);
                            setvalue(e.target.value);
                          }}
                        >
                          <option value="0">Open</option>
                          <option value="1">Close</option>
                        </Form.Select>
                      </div>
                    </td>
                      </tr>
                    );
                  })}
                  {/* <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="checked-in open">
                        <p>Open</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending closed">
                        <p>Closed</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="checked-in open">
                        <p>Open</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending closed">
                        <p>Closed</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="checked-in open">
                        <p>Open</p>
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, pagi?.totalCount)} of{" "}
                    {pagi?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={pagi?.totalCount}
                    limit={pagi?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Close this Ticket?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handlestaus}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
