import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import CommonHeader from "../Common/CommonHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteNotification,
  getnotificationlist,
} from "../Redux/Actions/AdminActions";
import SearchBar from "../Common/SerchBar";
import PaginationComponet from "../Components/Layout/Pagination";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

export default function Notifications() {
  const notificationData = useSelector((state) => state.notimgmt?.AllNoti);
  console.log(notificationData);
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [page, setPage] = useState(1);
  const [flag, setflag] = useState(true);
  const [showdel, setshowdel] = useState(false);
  const [ID, setId] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    dispatch(
      getnotificationlist({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const payload = {
      page: 1,
      limit: limit,
      search: search,
    };
    dispatch(getnotificationlist(payload));
  }, [limit, search, page, flag]);

  const handleDeleteNotification = async () => {
    const data = await dispatch(DeleteNotification({ notificationId: ID }));
    console.log(data);
    if (data?.payload?.data?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setflag(!flag);
      setshowdel(false);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Notification</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setsearch} />
                    </Form.Group>
                  </Form>
                </div>
                <div className="create-new-btn">
                  <button onClick={() => navigate("/create-notification")}>
                    Create New
                  </button>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Sent to</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationData?.notifications?.map((res, index) => {
                    const serialNumber =
                      ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <div className="user-id">
                            <p>{res?.title || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>{res?.desc || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>
                              {res?.send_to === 0
                                ? "Users"
                                : res?.send_to === 1
                                ? "Owners"
                                : "All"}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div
                            className="pending"
                            style={{ cursor:"pointer" }}
                            onClick={() => {
                              setshowdel(true);
                              setId(res?._id);
                            }}
                          >
                            <p>Delete</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, notificationData?.totalCount)} of{" "}
                    {notificationData?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={notificationData?.totalCount}
                    limit={notificationData?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to remove this notification ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteNotification}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
