import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch } from "react-redux";
import {
  DeleteUser,
  Suspenduser,
  getbookings,
  getuserprofile,
} from "../Redux/Actions/AdminActions";
import Logo from "../Assets/Images/place_holder-BHGM.png";
import PaginationComponet from "../Components/Layout/Pagination";
import CommonHeader from "../Common/CommonHeader";
import { Modal, Button, Toast } from "react-bootstrap";
import { toast } from "react-toastify";

export default function UserOverView() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [limit, setlimit] = useState(10);
  const [page, setpage] = useState(1);
  const [showd, setshowd] = useState(false);
  const [show, setshow] = useState(false);

  const [userdetails, setUserDetails] = useState({});
  const [bookingdetails, sebookingDetails] = useState([]);
  const [pagi, setpagi] = useState("");

  const [isDeleteChecked, setIsDeleteChecked] = useState(false);
  const [deleteError, setDeleteError] = useState("");

  const [isSuspendChecked, setIsSuspendChecked] = useState(false);
  const [suspendError, setSuspendError] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();

  const handleDeleteCheckboxChange = () => {
    setIsDeleteChecked(!isDeleteChecked);
    setDeleteError("");
  };

  const handleDeleteAccount = () => {
    if (!isDeleteChecked) {
      setDeleteError("You must confirm deletion.");
      setshowd(false);
    } else {
      dispatch(DeleteUser({ userId: id }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast?.success(res?.payload?.data?.message);
            setshowd(false);
            navigate("/user");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setIsDeleteChecked(false);
    }
  };

  const handleSuspendCheckboxChange = () => {
    setIsSuspendChecked(!isSuspendChecked);
    setSuspendError("");
  };

  const handleSuspendAccount = () => {
    if (!isSuspendChecked) {
      setSuspendError("You must confirm suspension.");
      setshow(false);
    } else {
      dispatch(Suspenduser({ userId: id, type: 0 }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast?.success(res?.payload?.data?.message);
            setshow(false);
            navigate("/user");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setIsSuspendChecked(false);
    }
  };

  useEffect(() => {
    dispatch(getuserprofile({ userId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setUserDetails(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      getbookings({
        page: page,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    dispatch(getbookings({ page, id, limit }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          sebookingDetails(res?.payload?.data?.data?.users);
          setpagi(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, id, limit]);

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box">
                <div className="my-overview-prof">
                  <img
                    src={
                      userdetails?.profile_image
                        ? `${url}${userdetails?.profile_image}`
                        : Logo
                    }
                  />
                  <h2>
                    {userdetails?.first_name && userdetails?.last_name
                      ? `${userdetails?.first_name} ${userdetails?.last_name}`
                      : "N/A"}
                  </h2>
                </div>

                <div className="profile-data">
                  <div className="total-view">
                    <div className="total-image-icon">
                      <img
                        src={
                          require("../Assets/Images/total-booking-icon.svg")
                            .default
                        }
                      />
                    </div>
                    <div className="text-veiw">
                      <p>{pagi.totalCount}</p>
                      <span>Total Bookings</span>
                    </div>
                  </div>
                  <div className="total-view">
                    <div className="total-image-icon">
                      <img
                        src={
                          require("../Assets/Images/cancelled-icon.svg").default
                        }
                      />
                    </div>
                    <div className="text-veiw">
                      <p>0</p>
                      <span>Cancelled</span>
                    </div>
                  </div>
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Username:{" "}
                          <span>
                            {userdetails?.first_name && userdetails?.last_name
                              ? `${userdetails?.first_name} ${userdetails?.last_name}`
                              : "N/A"}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Email: <span>{userdetails?.email || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Contact:{" "}
                          <span>
                            {userdetails?.country_code &&
                            userdetails?.phone_number
                              ? `${userdetails?.country_code} ${userdetails?.phone_number}`
                              : "N/A"}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-over-view">
              <div className="inner-box-images">
                <div className="toggle-box-top">
                  <div className="over-view-btn">
                    <p>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <circle
                            cx="6.75"
                            cy="5.25"
                            r="3"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 2.34741C13.3274 2.68729 14.2559 3.88341 14.2559 5.25366C14.2559 6.62392 13.3274 7.82003 12 8.15991"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.75 15.7498V14.2498C15.7422 12.8882 14.8184 11.7027 13.5 11.3623"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      Overview
                    </p>
                  </div>
                </div>

                <div className="outer-shadow-box">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Recent Activity</h2>
                      </div>

                      <div class="table-responsive new-over-view">
                        <Table size="sm" className="table-cmn">
                          <thead>
                            <tr>
                              <th>S.no.</th>
                              <th>Booking ID</th>
                              <th>Property Name</th>
                              <th>Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookingdetails?.map((res, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="">
                                      <div className="user-id">
                                        <p>{res?.bookingId || "N/A"}</p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="user-role">
                                      <p>{res?.property_name || "N/A"}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="user-role">
                                      <p>
                                        {res?.createdAt
                                          ? new Date(
                                              res.createdAt
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "short",
                                              year: "numeric",
                                            })
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={
                              res?.is_cancelled === 0 ? "checked-in" : "pending"
                            }>
                                      <p>{res?.is_cancelled === 0
                                ? "Completed"
                                : "Cancelled"}</p>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            {/* <tr>
                              <td>01</td>
                              <td>
                                <div className="">
                                  <div className="user-id">
                                    <p>XYZ123</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>Alpha Homes</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>22-Jan-2024 </p>
                                </div>
                              </td>
                              <td>
                                <div className="completed-btn">
                                  <p>Completed</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>01</td>
                              <td>
                                <div className="">
                                  <div className="user-id">
                                    <p>XYZ123</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>Alpha Homes</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>22-Jan-2024 </p>
                                </div>
                              </td>
                              <td>
                                <div className="pending">
                                  <p>Cancelled</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>01</td>
                              <td>
                                <div className="">
                                  <div className="user-id">
                                    <p>XYZ123</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>Alpha Homes</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>22-Jan-2024 </p>
                                </div>
                              </td>
                              <td>
                                <div className="completed-btn">
                                  <p>Completed</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>01</td>
                              <td>
                                <div className="">
                                  <div className="user-id">
                                    <p>XYZ123</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>Alpha Homes</p>
                                </div>
                              </td>
                              <td>
                                <div className="user-role">
                                  <p>22-Jan-2024 </p>
                                </div>
                              </td>
                              <td>
                                <div className="pending">
                                  <p>Cancelled</p>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </Table>
                        <div className="pagination-section">
                          <div className="showing-user">
                            <p>
                              Showing {1 + (page - 1) * limit} -{" "}
                              {Math.min(page * limit, pagi.totalCount)} of{" "}
                              {pagi.totalCount} results
                            </p>
                          </div>
                          <div className="pagination-block">
                            <PaginationComponet
                              currentPage={page}
                              totalCount={pagi.totalCount}
                              limit={pagi?.totalPages}
                              onPageChange={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="account-setting">
            <div className="delete-account">
              <div className="delete-heading-text">
                <h1>Delete Account</h1>
              </div>
              <div className="delete-disp">
                <p>Are you sure you want to delete this account?</p>
                <span>
                  Once you delete this account, there is no going back. Please
                  be certain.
                </span>
              </div>
              <div className="delete-check-box">
                {/* <div className="remember-check check-box-over">
                  <input
                    type="checkbox"
                    class="red"
                    id="filled-in-box"
                    checked={isDeleteChecked}
                    onChange={handleDeleteCheckboxChange}
                  />
                  <Form.Label>I confirm account Delete</Form.Label>
                </div> */}
                {/* {deleteError && (
                  <span className="error-message">{deleteError}</span>
                )} */}
              </div>
              <div className="btn-delete-account">
                <button onClick={() => setshowd(true)}>Delete account</button>
              </div>
            </div>

            <div className="delete-account">
              <div className="delete-heading-text">
                <h1>Suspend Account</h1>
              </div>
              <div className="delete-disp">
                <p>Are you sure you want to Suspend this account?</p>
                <span>
                  You will not be able to receive messages, notifications for up
                  to 24hours.
                </span>
              </div>
              <div className="delete-check-box">
                {/* <div className="remember-check check-box-over">
                  <input
                    type="checkbox"
                    class="red"
                    id="filled-in-box"
                    checked={isSuspendChecked}
                    onChange={handleSuspendCheckboxChange}
                  />
                  <Form.Label>I confirm account Suspension</Form.Label>
                </div> */}
                {/* {suspendError && (
                  <span className="error-message">{suspendError}</span>
                )} */}
              </div>
              <div className="btn-delete-account">
                <button onClick={() => setshow(true)}>Suspend account</button>
              </div>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to delete this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn done-btn" onClick={handleDeleteAccount}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshowd(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Suspend this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn done-btn" onClick={handleSuspendAccount}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
