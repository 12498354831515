import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import CommonHeader from "../Common/CommonHeader";

export default function Revenue() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
       <CommonHeader/>
      </Container>

      <Container fluid>
        <div className="revenue-pgae">
          <div className="total-revenue">
            <div className="select-period">
              <div className="num-of-revenue">
                <p>Today Revenue</p>
                <h2>19,860</h2>
                <span>Total Average Revenue per day 0</span>
              </div>
            </div>
          </div>
          <div className="total-revenue">
            <div className="select-period">
              <div className="num-of-revenue">
                <p>Today Revenue</p>
                <h2>19,860</h2>
                <span>Total Average Revenue per day 0</span>
              </div>
              <div className="select-period-btn">
                <button>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <rect
                        x="3.66675"
                        y="4.58325"
                        width="14.6667"
                        height="14.6667"
                        rx="2"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6666 2.75V6.41667"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.33333 2.75V6.41667"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66675 10.0833H18.3334"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0833 13.7501H10.9999"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.0001 13.75V16.5"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  Select period
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Revenue</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>10</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Property ID</th>
                    <th>Property Name</th>
                    <th>Owner Name</th>
                    <th>Contact</th>
                    <th> Date</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Alpha Homes</p>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Jordan Stevenson</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>₹ 1000</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Alpha Homes</p>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Jordan Stevenson</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>₹ 1000</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Alpha Homes</p>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Jordan Stevenson</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>₹ 1000</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Alpha Homes</p>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Jordan Stevenson</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>₹ 1000</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Alpha Homes</p>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Jordan Stevenson</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>₹ 1000</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Alpha Homes</p>
                      </div>
                    </td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Jordan Stevenson</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>₹ 1000</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
