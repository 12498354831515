import { createSlice } from "@reduxjs/toolkit";
import { usermanagement } from "../Actions/AdminActions";

export const usermanageSlice = createSlice({
    name: "usermanageSlice",
    initialState: {
        alldata: "",
        loading: false,
        error: null,
    },
    reducers: {
        // You can add other reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(usermanagement.pending, (state) => {
                state.loading = true; 
                state.error = null;   
            })
            .addCase(usermanagement.fulfilled, (state, action) => {
                state.loading = false; 
                state.alldata = action.payload.data; 
            })
            .addCase(usermanagement.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default usermanageSlice.reducer;
