import { createSlice } from "@reduxjs/toolkit";
import { getdashboard } from "../Actions/AdminActions";


export const dashboardSlice = createSlice({
    name: "dashboardSlice",
    initialState: {
        alldata: [],
        loading: false,
        error: null,
    },
    reducers: {
        // You can add other reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(getdashboard.pending, (state) => {
                state.loading = true; 
                state.error = null;   
            })
            .addCase(getdashboard.fulfilled, (state, action) => {
                state.loading = false; 
                state.alldata = action?.payload?.data?.data; 
            })
            .addCase(getdashboard.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default dashboardSlice.reducer;
