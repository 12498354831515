import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import User from "./Pages/User";
import Dashboard from "./Pages/Dashboard";
import ChangePassword from "./Pages/ChangePassword";
import UserOverView from "./Pages/UserOverView";
import UserProperty from "./Pages/UserProperty";
import Booking from "./Pages/Booking";
import BookingInformation from "./Pages/BookingInformation";
import Revenue from "./Pages/Revenue";
import Notifications from "./Pages/Notifications";
import CreateNotification from "./Pages/CreateNotification";
import ChatSupport from "./Pages/ChatSupport";
import ChatBox from "./Pages/ChatBox";
import Propertyowner from "../src/Pages/Propertyowner";
import ProtectedRoutes from "./Components/Layout/Protectedroutes";
import PropertyOwnersOvreview from "./Pages/PropertyOwnerOverview";
import { ProfileProvider } from "./Context/ProfileContext";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ProfileProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/otp-varification" element={<OtpVarification />}></Route>

          <Route element={<ProtectedRoutes />}>
          <Route
            path="/property-owners-ovreview/:id"
            element={<PropertyOwnersOvreview />}
          ></Route>
            <Route path="/error" element={<Error />}></Route>
            <Route
              path="/under-maintenance"
              element={<UnderMaintenance />}
            ></Route>
            <Route path="/user" element={<User />}></Route>
            <Route path="/propery-owners" element={<Propertyowner />}></Route>
            <Route path="/my-profile" element={<MyProfile />}></Route>

            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/change-password" element={<ChangePassword />}></Route>
            <Route
              path="/user-over-view/:id"
              element={<UserOverView />}
            ></Route>
            <Route path="/user-property/:id" element={<UserProperty />}></Route>
            <Route path="/booking" element={<Booking />}></Route>
            <Route
              path="/booking-information/:id"
              element={<BookingInformation />}
            ></Route>
            <Route path="/revenue" element={<Revenue />}></Route>
            <Route path="/notifications" element={<Notifications />}></Route>
            <Route
              path="/create-notification"
              element={<CreateNotification />}
            ></Route>
            <Route path="/chat-support" element={<ChatSupport />}></Route>
            <Route path="/chat-box" element={<ChatBox />}></Route>
            
          </Route>
        </Routes>
        </ProfileProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
