import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  return (
    <aside>
      <div className="sidebar">
        <div className="logo-side">
          <Link to="/user">
            <img
              className="big"
              src={require("../../Assets/Images/side-bar-logo.svg").default}
            />
          </Link>
        </div>
        <div className="side-menu">
          <div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/Dashboard"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/Dashboard"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2.5"
                    y="10"
                    width="5"
                    height="6.66667"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="7.5"
                    y="6.66675"
                    width="5"
                    height="10"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="12.5"
                    y="3.33325"
                    width="5"
                    height="13.3333"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.3335 16.6667H15.0002"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Dashboard</p>
              </Link>
            </div>

            <div className="side-pages">
              <p>PAGES</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="11.0002"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> User Management</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/booking"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/booking"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                >
                  <path
                    d="M8.25016 5.27783H6.41683C5.40431 5.27783 4.5835 6.09864 4.5835 7.11117V18.1112C4.5835 19.1237 5.40431 19.9445 6.41683 19.9445H15.5835C16.596 19.9445 17.4168 19.1237 17.4168 18.1112V7.11117C17.4168 6.09864 16.596 5.27783 15.5835 5.27783H13.7502"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="8.25"
                    y="3.44458"
                    width="5.5"
                    height="3.66667"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 13.5279L10.0833 15.3612L13.75 11.6946"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Booking</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/revenue"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/revenue"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                >
                  <path
                    d="M8.25016 5.27783H6.41683C5.40431 5.27783 4.5835 6.09864 4.5835 7.11117V18.1112C4.5835 19.1237 5.40431 19.9445 6.41683 19.9445H15.5835C16.596 19.9445 17.4168 19.1237 17.4168 18.1112V7.11117C17.4168 6.09864 16.596 5.27783 15.5835 5.27783H13.7502"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="8.25"
                    y="3.44458"
                    width="5.5"
                    height="3.66667"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8332 10.7778H10.5415C9.78211 10.7778 9.1665 11.3934 9.1665 12.1528C9.1665 12.9122 9.78211 13.5278 10.5415 13.5278H11.4582C12.2176 13.5278 12.8332 14.1434 12.8332 14.9028C12.8332 15.6622 12.2176 16.2778 11.4582 16.2778H9.1665"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.7498 16.278C11.7498 15.8638 11.4141 15.528 10.9998 15.528C10.5856 15.528 10.2498 15.8638 10.2498 16.278H11.7498ZM10.2498 17.1947C10.2498 17.6089 10.5856 17.9447 10.9998 17.9447C11.4141 17.9447 11.7498 17.6089 11.7498 17.1947H10.2498ZM11.7498 9.86133C11.7498 9.44711 11.4141 9.11133 10.9998 9.11133C10.5856 9.11133 10.2498 9.44711 10.2498 9.86133H11.7498ZM10.2498 10.778C10.2498 11.1922 10.5856 11.528 10.9998 11.528C11.4141 11.528 11.7498 11.1922 11.7498 10.778H10.2498ZM10.2498 16.278V17.1947H11.7498V16.278H10.2498ZM10.2498 9.86133V10.778H11.7498V9.86133H10.2498Z"
                    fill="#2F2B3D"
                    fill-opacity="0.9"
                  />
                </svg>

                <p>Revenue</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/notifications"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/notifications"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 4.58333C9.1665 3.57081 9.98732 2.75 10.9998 2.75C12.0124 2.75 12.8332 3.57081 12.8332 4.58333C14.9783 5.59767 16.3886 7.71306 16.4998 10.0833V12.8333C16.6397 13.9891 17.3201 15.0097 18.3332 15.5833H3.6665C4.67953 15.0097 5.35994 13.9891 5.49984 12.8333V10.0833C5.61109 7.71306 7.02135 5.59767 9.1665 4.58333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 15.5835V16.5002C8.25 18.0189 9.48122 19.2502 11 19.2502C12.5188 19.2502 13.75 18.0189 13.75 16.5002V15.5835"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Notifications</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/chat-support"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/chat-support"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                >
                  <rect
                    x="3.6665"
                    y="12.6112"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="14.6665"
                    y="12.6112"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.6665 14.4445V11.6945C3.6665 7.64445 6.94975 4.36121 10.9998 4.36121C15.0499 4.36121 18.3332 7.64445 18.3332 11.6945V14.4445"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 18.1112C16.5 19.63 14.0376 20.8612 11 20.8612"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Chat Support</p>
              </Link>
            </div>

            {/* <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/change-password"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/change-password"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                >
                  <rect
                    x="4.5835"
                    y="10.7783"
                    width="12.8333"
                    height="9.16667"
                    rx="2"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <ellipse
                    cx="11.0002"
                    cy="15.3615"
                    rx="0.916667"
                    ry="0.916667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.3335 10.7782V7.11149C7.3335 5.08645 8.97512 3.44482 11.0002 3.44482C13.0252 3.44482 14.6668 5.08645 14.6668 7.11149V10.7782"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Change Password</p>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </aside>
  );
}
