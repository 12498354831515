import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const COLORS = ["#FFAC33", "#158A44",]; // Define colors for the chart

const CategoryChart = ({dashData}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const staticData = [
      { label: "Cancelled Bookings percentage", count: dashData?.cancelledBookingPercentage ?  dashData?.cancelledBookingPercentage :0 },
      { label: "Active Bookings percentage", count: dashData?.activeBookingsPercentage ?  dashData?.activeBookingsPercentage :0 },
    ];
    // Prepare the data for Recharts
    const formattedData = staticData.map((item) => ({
      name: item.label, // "label" now refers to the name of the category
      value: item.count, // "count" remains the value for the pie slices
    }));
    setData(formattedData);
  }, [dashData]);

  // Calculate the total value for displaying in the center
  const totalValue = dashData?.allBookings ?  dashData?.allBookings  : 0 // Update this to match the total percentage

  // Custom tooltip component
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0];// Get the value for the current slice
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`${name} ${value} %`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <PieChart>
          <Tooltip content={<CustomTooltip />} />
          <Pie
            data={data}
            dataKey="value" // Field to extract the donut slice size
            nameKey="name" // Field for the slice label
            cx="50%" // Center X-axis
            cy="50%" // Center Y-axis
            innerRadius={110} // Inner radius for the donut hole
            outerRadius={150} // Outer radius for the size of the chart
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          {/* Add centered text */}
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Total: {totalValue}
          </text>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CategoryChart;
