import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponet = ({ currentPage, totalCount, limit, onPageChange }) => {

  const pageItems = [];
  for (let number = 1; number <= limit; number++) {
    pageItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => onPageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
     <Pagination.First
        onClick={() => onPageChange(0)} // Navigate to the first page (0)
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1} // Disable if on first page (0)
      />
      {pageItems}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === limit } // Disable if on last page
      />
      <Pagination.Last
        onClick={() => onPageChange(currentPage[pageItems.length -1] )} // Navigate to the last page (totalPages - 1)
        disabled={currentPage === limit }
      />
    </Pagination>
  );
};

export default PaginationComponet;

