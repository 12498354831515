import { configureStore } from "@reduxjs/toolkit";
import { usermanageSlice } from "./Reducer/UsermgmtSlice";
import { notificationSlice } from "./Reducer/NotificationSlice";
import { dashboardSlice } from "./Reducer/DashboardSlice";



export const Store = configureStore({
  reducer: {
    usermgt:usermanageSlice.reducer,
    notimgmt:notificationSlice.reducer,
    dashboard:dashboardSlice.reducer,
  },
});